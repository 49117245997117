<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Kategori Obat</b> yang ada di Klinik IONA
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/masters/medicine-categories/add')"
      >Tambah</b-button>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template
            v-slot:body
            v-if="dataLoaded"
          >
            <div class="overflow-auto">
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '35%' : '' }"
                  />
                </template>
                <template #cell(actions)='data'>
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover title="Detail"
                    @click="$router.push({ path: '/masters/medicine-categories/detail/' + data.item.id })"
                  > <i class="fas fa-eye px-0"></i> </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover title="Edit"
                    @click="$router.push({ path: '/masters/medicine-categories/edit/' + data.item.id })"
                  > <i class="fas fa-edit px-0"></i> </b-button>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover title="Hapus"
                    @click="deleteData(data.item.id)"
                  > <i class="fas fa-trash px-0"></i> </b-button>
                </template>
              </b-table>
              <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @pageOnClick="pageOnClick"
              class="mt-4"
              ></b-pagination>
            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: "List",

  components: {
    Card
  },

  data() {
    return {
      // pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      manipulateBtn:false,
    }
  },

  methods: {

    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      let response = await module.list('medicine-categories', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1  
      for(a = 0; a < response.data.length; a++){
        response.data[a].number = b + a
      }

      this.items = response.data
    },

    async pagination() {
      let pagination = await module.pagination("medicine-categories");
      this.perPage = pagination.meta.pagination.per_page
      this.totalRows = pagination.data.length
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete('medicine-categories/' + id)
      // If Deleted
      if (result) {
        this.list()
      }
    },

    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      console.log("looping")
      
      if(access_right[a] == "9003"){
        this.manipulateBtn = true
      }
      
    }
    },


  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kategori Obat" }])
    // Get Data
    this.list()
    this.setActiveMenu()
  },

}

</script>
